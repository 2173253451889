button.coinbase-commerce-button {
    border-radius: 6px;
    background-color: #0667D0;
    background: linear-gradient(#0667D0, #0655AB);
    color: white;
    height: 40px;
    font-size: 14px;
    font-family: Avenir Next, sans-serif;
    font-weight: 500;
    user-select: none;
    user-drag: none;
    -webkit-user-drag: none;
    text-decoration: none;
    cursor: pointer;
}

button.coinbase-commerce-button:hover {
  background: #0666D0;
}
button.coinbase-commerce-button:active {
  background: #0655AB;
}
button.coinbase-commerce-button:focus {
  outline: none;
}
button.coinbase-commerce-button:disabled {
  background: #7D95B6;
  color: rgba(0,0,0,.4);
  cursor: not-allowed;
}

button.coinbase-commerce-button > span {
  color: white;
  font: normal 500 14px/20px -apple-system, BlinkMacSystemFont, ".SFNSText-Regular", "San Francisco", "Roboto", "Segoe UI", "Helvetica Neue", "Lucida Grande", sans-serif;
  letter-spacing: 0;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  text-shadow: 0 1px 0 rgba(0,0,0,0.15);
  white-space: nowrap;
}

div.commerce-loading-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -20px;
    margin-left: -20px;
    width: 40px;
    height: 40px;
    border: 3px solid rgba(6, 103, 208, 0.05);
    border-radius: 100%;
    border-top-color: white;
    animation: spin 1s infinite linear;
}

@keyframes spin {
    33% {
        transform: rotate(90deg);
    }
    66% {
        transform: rotate(270deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

div.coinbase-commerce-iframe-container {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 999980;

    background-color: rgba(0, 0, 0, 0.5);
}

iframe.coinbase-commerce-iframe {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 99999;
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255);
    margin: auto;
    max-width: 800px;
    border: none;
}

